<template>
    <div class="full-height-layout fill">
        <form class="full-height-layout fill form form--supplier-save" is="json-form" ref="form" :action="action" @success="saveOffice" @error="onError" :values="values">
            <template slot-scope="form">
                <mercur-stepper :active-step.sync="stepsActive" is-full="true" class="fill pl-5 pr-5 pt-5">
                    <mercur-step id="office" title="First Step: Office data">
                        <mercur-card class="mx-4 mb-3">
                            <location-office-fields :location="form.data.location" :v="$v.values.location"  location-type="OFFICE"></location-office-fields>
                        </mercur-card>
                    </mercur-step>
                    <mercur-step id="finance" title="Second Step: Finance data">
                        <mercur-card class="mx-4 mb-3">
                            <location-finance-fields :location="form.data.office" :v="$v.values.office"></location-finance-fields>
                        </mercur-card>
                    </mercur-step>
                    <mercur-step v-if="formType === 'add'" id="facility" title="Third Step: Add facility data">
                        <mercur-card class="mx-4 mb-3">
                            <h2 class="font-weight-normal">Facility</h2>
                            <div class="row">
                                <mercur-card class="mx-4 mb-3" v-if="form.data.location">
                                    {{form.data.location.locationName}}<br />
                                    {{form.data.location.address}} {{form.data.location.houseNumber}} {{form.data.location.houseNumberAdditional}}<br />
                                    {{form.data.location.addressAdditional}}<br />
                                    {{form.data.location.zipCode}} {{form.data.location.city}}<br />
                                    {{form.data.location.country}}
                                </mercur-card>
                                <div class="col-12 mt-3">
                                    <mercur-checkbox v-model="facility_same_address">This address is also a facility</mercur-checkbox>
                                    <div class="text-secondary">
                                        Select if your office and facility are located at the same address
                                    </div>
                                </div>

                            </div>
                            <div class="row" v-if="facility_same_address">
                                <div class="col-12">
                                    <mercur-input v-model="form.data.facility.locationName">
                                        Facility name - optional
                                    </mercur-input>
                                </div>
                                <div class="col-12">
                                    <mercur-input v-model="form.data.facility.emailAddress">
                                        Email
                                    </mercur-input>
                                </div>
                                <div class="col-12">
                                    <mercur-input v-model="form.data.facility.phone">
                                        Phone number
                                    </mercur-input>
                                </div>
                            </div>
                            <div class="row" v-if="facility_same_address">
                                <div class="col-12">
                                    <working-days-fields v-model="form.data.facility.workingDays" :enable-cutoff-time="true" :enable-working-hours="true"></working-days-fields>
                                </div>
                            </div>
                        </mercur-card>
                    </mercur-step>
                </mercur-stepper>

                <p v-if="form.errors.message" v-text="form.errors.message"></p>

                <div data-test="loading" class="loading-overlay" v-if="form.loading">
                    <mercur-progress-bar indeterminate></mercur-progress-bar>
                </div>

                <div class="text-right py-2">
                    <mercur-button class="btn text-uppercase" @click="nextStep" v-if="shouldShowNextButton">Next step</mercur-button>
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" type="submit" :disabled="form.loading || $v.$invalid">Save office</mercur-button>
                </div>
            </template>
        </form>
    </div>
</template>

<script>
import JsonForm from '@/components/utils/JsonForm'
import { mapGetters } from 'vuex'
import CONFIG from '@root/config'

import LocationOfficeFields from '@/components/LocationOfficeFields'
import LocationFinanceFields from '@/components/LocationFinanceFields'
import WorkingDaysFields from '@/components/WorkingDaysFields'
import LocationBase from '@/views/supplier/locations/LocationBase'

export default {
    name: 'SupplierAddOfficeView',
    components: { LocationFinanceFields, LocationOfficeFields, WorkingDaysFields, JsonForm },
    mixins: [ LocationBase ],
    computed: {
        ...mapGetters('suppliers', ['getSupplierById']),
        action () {
            let url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_ADD

            if (this.formType === 'edit') {
                url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_UPDATE.replace('{locationId}', this.$route.params.officeId)
            }

            return url.replace('{supplierId}', this.supplierId)
        },
        formType () {
            return this.$route.params.officeId ? 'edit' : 'add'
        },
        shouldShowNextButton () {
            if (this.stepsActive === 'office') {
                return true
            }

            if (this.formType === 'add' && this.stepsActive === 'finance') {
                return true
            }

            return false
        },
    },

    data () {
        return {
            locationType: 'OFFICE',
            facility_same_address: false,
            values: {
                location: {
                    certifications: [],
                    facilitySettings: {},
                },
                office: {
                    financeSettings: {

                    },
                },
                facility: {
                    workingDays: [],
                },
            },
            stepsActive: 'office',
            steps: {
                active: 'office',

                office: true,
                finance: true,
                facility: true,

                errors: {
                    office: null,
                    finance: null,
                },
            },
            url: CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId),
        }
    },

    watch: {
        facility_same_address (value) {
            if (value) {
                this.values.facility = {
                    workingDays: [],
                }
            } else {
                this.$delete(this.values, 'facility')
            }
        },
    },

    methods: {
        nextStep () {
            if (this.stepsActive === 'office') {
                this.$v.values.location.$touch()
                if (this.$v.values.location.$invalid) {
                    return
                }
                this.stepsActive = 'finance'
                this.$v.reset()
            }

            if (this.stepsActive === 'finance' && this.formType === 'add') {
                this.$v.values.office.$touch()
                if (this.$v.values.office.$invalid) {
                    return
                }
                this.stepsActive = 'facility'
                this.$v.reset()
            }
        },
        setDone (id, index) {
            this.steps.errors[this.steps.active] = null
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.steps[id] = false
                this.steps.errors[this.steps.active] = 'Please check the errors'

                return
            }

            this.steps[id] = true

            if (this.formType === 'edit' && id === 'finance') {
                this.$refs.form.submit()
            }

            if (index) {
                this.steps.active = index
            }
        },
        saveOffice (data) {
            if (data.message === 'location.creation.successful') {
                this.$root.$emit('notification:global', {
                    message: 'Office added',
                })

                this.$router.push({
                    name: 'SupplierLocations',
                    params: {
                        supplierId: this.supplierId,
                    },
                })
            }

            if (data.message === 'location.update.successful') {
                this.$root.$emit('notification:global', {
                    message: 'Office saved',
                })

                this.$router.push({
                    name: 'SupplierLocations',
                    params: {
                        supplierId: this.supplierId,
                    },
                })
            }
        },
        onError (error) {
            this.$root.$emit('notification:global', {
                type: 'error',
                errors: error,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .form--supplier-save {
        padding-left: 7px;
        padding-right: 7px;
    }
</style>
